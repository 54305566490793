import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { BLOCKS } from "@contentful/rich-text-types";
import { Document } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Image from "components/image";
import { ILinks } from "utils/contentfulModel";

function renderOptions(links?: ILinks) {
  // create an asset map
  const assetMap = new Map();
  if (links) {
    // loop through the assets and add them to the map
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  return {
    // other options...

    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
        <Typography sx={{ mb: 5 }}>{children}</Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
        const asset = assetMap.get(node.data.target.sys.id);
        return asset != undefined ? <img src={asset.url} alt="" /> : <p />;
      },
      [BLOCKS.UL_LIST]: (node: any, children: any) => (
        <ul>{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: any) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
        <ListItem
          component={"span"}
          dense
          sx={{ px: 1, alignItems: "flex-start", display: "list-item" }}
        >
          <ListItemText primary={children} />
        </ListItem>
      ),
      [BLOCKS.QUOTE]: (node: any, children: any) => (
        <Box
          sx={{
            backgroundColor: "#fff",
            p: "10px",
            borderRadius: 2,
            position: "relative",
            "& .MuiTypography-root": { m: 0 },
          }}
        >
          <FormatQuoteIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              top: "-12px",
              position: "absolute",
              fontSize: 28,
            }}
          />

          <Box sx={{ mx: 5, my: "20px" }}>
            <Typography
              component={"span"}
              sx={{ fontStyle: "italic", fontWeight: 600 }}
            >
              {children}
            </Typography>
          </Box>

          <FormatQuoteIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              bottom: "-12px",
              right: "7px",
              position: "absolute",
              fontSize: 28,
            }}
          />
        </Box>
      ),
    },
  };
}

export interface RichTextFieldProps {
  document: Document;
  links?: ILinks;
}

const RichTextField = ({ document, links }: RichTextFieldProps) => {
  return (
    <span>
      {document != null
        ? documentToReactComponents(document, renderOptions(links))
        : ""}
    </span>
  );
};

export default RichTextField;
