import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Grid,
  CardActionArea,
  Box,
  Button,
  Tooltip,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SequenceDto } from "api";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setSequence } from "store/slices/SequenceFormSlice";
import { getLevelTitle, getStatusText } from "utils/model";
import dayjs from "dayjs";
import "dayjs/locale/sv";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Iconify from "components/iconify";
import SequenceLikeButton from "components/sequence/SequenceLikeButton";
import SequenceCommentButton from "components/sequence/SequenceCommentButton";
import { useLocales } from "locales";

dayjs.locale("sv");

export interface SequenceCardProps {
  data: SequenceDto;
  handleClick: (sequence: SequenceDto) => void;
}
export default function SequenceCard({ data, handleClick }: SequenceCardProps) {
  const sequenceimage = data?.image?.sequenceImageId
    ? data?.image?.path
    : "missingpng.png";
  const startPath = "\\SequenceImages\\";
  const endPath = `${sequenceimage}`;
  const fullPath = startPath + endPath;

  const dispatch = useDispatch();
  const { t } = useLocales();

  const renderUserInfo = (
    <Stack direction={"row"} spacing={1} sx={{ border: 0, flex: 1 }}>
      <Box>
        {data.status !== 1 ? (
          <>
            <Link
              to={"/user/profile/" + data.user?.id}
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600, color: "#000" }}
              >
                {data.user?.firstName} {data.user?.lastName}
              </Typography>
            </Link>
            <Typography
              sx={{
                opacity: "50%",
                fontSize: "11px",
              }}
            >
              {dayjs(data.modifiedDate!).format("YYYY-MM-DD")}
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ fontSize: "11px" }}>
              {t(getStatusText(data.status!))}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"}>
          {data.status! < 2 && (
            <Button
              color="primary"
              variant="contained"
              sx={{
                maxWidth: { xs: 98, md: 98 },
                maxHeight: { xs: 31, md: 31 },
                justifyContent: "center",
              }}
              component={Link}
              to={"/newSequence"}
              onClick={() => dispatch(setSequence(data))}
            >
              {t("continue")}
            </Button>
          )}
          {data.private && (
            <Iconify icon="tabler:lock" width={24} sx={{ mr: "3px" }} />
          )}
          {data.status! === 2 && (
            <SequenceCommentButton
              item={data}
              sx={{ "&.MuiIconButton-root": { p: "1px" }, mr: "3px" }}
            />
          )}
          {data.status! === 2 && <SequenceLikeButton item={data} />}
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "15px",
        boxShadow: "0px 1px 24px #0000000F",
        minHeight: "314px",
      }}
    >
      <CardActionArea
        onClick={() => handleClick(data!)}
        sx={{ borderRadius: "15px 15px 0px 0px" }}
      >
        <Box
          sx={{
            p: "15px 15px 0px 15px",
          }}
        >
          <CardMedia
            component="img"
            image={`${fullPath}`}
            alt="sequence"
            title=""
            sx={{
              height: "177px",
              borderRadius: "7px",
            }}
          />
        </Box>

        <CardContent
          sx={{
            p: "8px 15px 15px 15px",
          }}
        >
          <Tooltip title={data.title}>
            <Typography noWrap sx={{ fontSize: "18px" }}>
              {data.title}
            </Typography>
          </Tooltip>

          <Grid container spacing={1}>
            <Grid item>
              <Typography
                sx={{
                  opacity: "50%",
                  fontSize: "13px",
                }}
              >
                {`${t(getLevelTitle(data.level!))}, ${t("positions")}: ${
                  data.sequenceItems?.length
                }`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          pl: "15px",
          pb: "15px",
          pr: "15px",
          pt: "0px",
        }}
      >
        {renderUserInfo}
      </CardActions>
    </Card>
  );
}
