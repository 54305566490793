import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  Grid,
  List,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SequenceDto } from "api/models/SequenceDto";

import { IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  copySequence,
  sequenceFormStateSelector,
  setPrivate,
  setSequence,
  updateExistingSequence,
} from "store/slices/SequenceFormSlice";
import { getLevelTitle, getYogaFormText } from "utils/model";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import SequenceDeleteDialog from "components/sequence/SequenceDeleteDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonIcon from "@mui/icons-material/Person";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SequenceListItem from "components/sequence/SequenceListItem";
import { useReactToPrint } from "react-to-print";
import { SequencePrintView } from "views/OtherViews/SequencePrintView";
import SequenceCommentButton from "components/sequence/SequenceCommentButton";
import DrawerHeader from "components/drawers/DrawerHeader";
import DrawerContent from "components/drawers/DrawerContent";
import SequenceShareDialog from "components/sequence/SequenceShareDialog";
import VideoDialog from "components/video/VideoDialog";
import { useLocales } from "locales";
import TranslateField from "components/language/TranslateField";
import Iconify from "components/iconify";
import TranslateService from "utils/TranslateService";
import { TranslateDto } from "locales/mstranslate";

export interface SequencesInfoViewProps {
  sequence?: SequenceDto | null;
  handleClose?: () => void;
}

export default function SequenceInfoView({
  sequence,
  handleClose,
}: SequencesInfoViewProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, currentLang } = useLocales();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { currentUser } = useSelector(authStateSelector);
  const sequenceForm = useSelector(sequenceFormStateSelector);

  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [script, setScript] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  const [itemsData, setItemsData] = useState<string[]>([]);

  useEffect(() => {
    dispatch(setSequence(sequence!));

    setTitle(sequence!.title!);
    setTheme(sequence!.theme!);
    setScript(sequence!.script!);
    setOtherInformation(sequence!.otherInformation!);
  }, [sequence]);

  function handleCopySequence(sequence: SequenceDto): void {
    dispatch(copySequence(sequence!));
    navigate("/newSequence");
  }

  function handleEditSequence(sequence: SequenceDto): void {
    dispatch(setSequence(sequence!));
    navigate("/newSequence");
  }

  function handlePrivateChange(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setPrivate(e.target.checked));
    dispatch<any>(updateExistingSequence());
  }

  function handleShareSequence(sequenceId: number): void {
    console.log("Shared");
  }

  async function handleTranslate(): Promise<void> {
    const textData = [
      { text: sequence!.title ? sequence!.title! : "" },
      { text: sequence!.theme ? sequence!.theme! : "" },
      { text: sequence!.script ? sequence!.script! : "" },
      { text: sequence!.otherInformation! ? sequence!.otherInformation! : "" },
    ];

    // Add for sequenceItems
    const itemsTextData = sequence?.sequenceItems?.map((item) => {
      return { text: item.information ? item.information : "" };
    });

    const allTextData = [...textData, ...itemsTextData!];

    console.log(allTextData);

    await TranslateService.post("/translate", allTextData).then((response) => {
      // translatedText = JSON.stringify(response.data, null, 4);

      const translations = response.data as TranslateDto[];

      const titleTranslation = translations[0].translations!.filter(
        (t) => t.to === currentLang.value
      );
      setTitle(titleTranslation[0].text!);
      const themeTranslation = translations[1].translations!.filter(
        (t) => t.to === currentLang.value
      );
      setTheme(themeTranslation[0].text!);
      const scriptTranslation = translations[2].translations!.filter(
        (t) => t.to === currentLang.value
      );
      setScript(scriptTranslation[0].text!);
      const otherInformationTranslation = translations[3].translations!.filter(
        (t) => t.to === currentLang.value
      );
      setOtherInformation(otherInformationTranslation[0].text!);

      const translatedTextItems: string[] = [];
      for (var i = 4; i < translations.length; i++) {
        const itemTranslation = translations[i].translations!.filter(
          (t) => t.to === currentLang.value
        );
        translatedTextItems.push(itemTranslation[0].text!);
      }

      setItemsData(translatedTextItems);
    });
  }

  return (
    <>
      <div style={{ display: "none" }}>
        <SequencePrintView sequence={sequence} ref={componentRef} />
      </div>
      <DrawerHeader
        handleClose={() => handleClose!()}
        sx={{ backgroundColor: "#FFFFFF" }}
        toolbar={
          <Stack direction={"row"}>
            <Tooltip title={t("translate")}>
              <span>
                <IconButton aria-label="translate" onClick={handleTranslate}>
                  <Iconify width={24} icon={"flowbite:language-outline"} />
                </IconButton>
              </span>
            </Tooltip>
            <SequenceCommentButton item={sequence!} />
            <Tooltip title={t("copy")}>
              <span>
                <IconButton
                  aria-label="copy"
                  disabled={sequence?.user?.id !== currentUser?.id}
                  onClick={() => handleCopySequence(sequence!)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  aria-label="edit"
                  disabled={sequence?.user?.id !== currentUser?.id}
                  onClick={() => handleEditSequence(sequence!)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>

            <SequenceShareDialog
              sequence={sequence!}
              disabled={sequence?.user?.id !== currentUser?.id}
              onShared={handleShareSequence}
            />
            <SequenceDeleteDialog
              sequence={sequence!}
              disabled={sequence?.user?.id !== currentUser?.id}
              handleDeleted={handleClose!}
            />

            <Tooltip title={t("print")}>
              <span>
                <IconButton aria-label="print" onClick={handlePrint}>
                  <PrintIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        }
      />
      <DrawerContent>
        <Stack
          sx={{
            width: { xs: "100%", sm: "600px", md: "900px" },
          }}
        >
          <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={600}
              noWrap
              sx={{ maxWidth: { xs: "390px", sm: "100%" } }}
            >
              {title ? title : "[Ny sekvens]"}
            </Typography>
            {(sequenceForm.sequence!
              ? sequenceForm.sequence!.private
              : false) && <LockOutlinedIcon fontSize="small" />}
          </Stack>

          <Grid container spacing={1} sx={{ pt: "10px" }}>
            <Grid item xs={12} sm={6} sx={{}}>
              <Stack spacing={2} sx={{}}>
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    p: 2,
                  }}
                >
                  <Table
                    size="small"
                    sx={{
                      "& .MuiTableCell-root": {
                        borderBottom: "none",
                        padding: 0,
                      },
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <SignalCellularAltIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            {t("level")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {t(getLevelTitle(sequence?.level!))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <SportsGymnasticsIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            {t("yogaForm")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {t(getYogaFormText(sequence?.yogaForm!))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <FormatListBulletedIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            {t("positions")}
                          </Typography>
                        </TableCell>
                        <TableCell>{sequence!.sequenceItems?.length}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <PersonIcon sx={{ pr: "10px" }} fontSize="small" />
                          <Typography fontSize={14} fontWeight={600}>
                            {t("createdBy")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={"/user/profile/" + sequence!.user?.id}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              {sequence!.user?.firstName}{" "}
                              {sequence!.user?.lastName}
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ display: "flex" }}>
                          <LockOutlinedIcon
                            sx={{ pr: "10px" }}
                            fontSize="small"
                          />
                          <Typography fontSize={14} fontWeight={600}>
                            {t("private")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            disabled={sequence?.user?.id !== currentUser?.id}
                            control={
                              <Switch
                                size="small"
                                onChange={(e) => handlePrivateChange(e)}
                                checked={
                                  sequenceForm.sequence!
                                    ? sequenceForm.sequence!.private
                                    : false
                                }
                              />
                            }
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    p: 2,
                  }}
                >
                  <Stack>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("theme")}
                    </Typography>
                    <Typography
                      style={{ overflowWrap: "break-word" }}
                      sx={{ pb: "15px" }}
                    >
                      {theme ? theme : t("nothingSpecified")}
                    </Typography>
                    {sequence?.videos && sequence?.videos.length > 0 && (
                      <>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Video
                        </Typography>
                        <List dense>
                          {sequence?.videos &&
                            sequence?.videos.map((v, index) => (
                              <VideoDialog video={v} index={index} />
                            ))}
                        </List>
                      </>
                    )}

                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("script")}
                    </Typography>
                    <Typography
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-line",
                      }}
                      sx={{ pb: "15px" }}
                    >
                      {script ? script : t("nothingSpecified")}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("musicLink")}
                    </Typography>
                    <Typography
                      style={{ overflowWrap: "break-word" }}
                      sx={{ pb: "15px", maxWidth: { xs: "330px" } }}
                    >
                      {sequence!.musicLink
                        ? sequence!.musicLink
                        : t("nothingSpecified")}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("otherInformation")}
                    </Typography>
                    <Typography
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-line",
                      }}
                      sx={{ pb: "15px" }}
                    >
                      {otherInformation
                        ? otherInformation
                        : t("nothingSpecified")}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} sx={{}}>
              <Typography
                variant="h6"
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                Program
              </Typography>
              <List
                sx={{
                  p: 0,
                  "& .MuiListItem-root": {
                    mx: 0,
                    "&:first-of-type": { mt: 0 },
                  },
                }}
              >
                {sequence!.sequenceItems &&
                  sequence!.sequenceItems.map((item, index) => (
                    <SequenceListItem
                      key={index}
                      item={item}
                      translateText={itemsData[index]}
                    />
                  ))}
              </List>
            </Grid>
          </Grid>
        </Stack>
      </DrawerContent>
    </>
  );
}
