import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PositionDto,
  PositionService,
  SequenceDto,
  SequenceImageDto,
  SequenceService,
} from "api";
import { AppThunk, RootState } from "store";
import { updatePos } from "./positionSlice";

export interface PositionFormState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  position: PositionDto | null;
}

const initialState: PositionFormState = {
  loading: false,
  hasError: false,
  error: "",
  position: null,
};

export const positionFormSlice = createSlice({
  name: "positionForm",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loading = false;
      state.error = `${action.payload}`;
    },
    setPosition: (state, action: PayloadAction<PositionDto>) => {
      state.position = action.payload;
    },
  },
});

export const { hasError, loading, setPosition } = positionFormSlice.actions;

export const updatePosition =
  (
    mainPurpose: string,
    mainPurposeEnglish: string,
    level: number,
    types: number[],
    sanskrit: string,
    english: string,
    swedish: string,
    isPrivate: boolean,
    prePose1Id: number,
    prePose2Id: number,
    prePose3Id: number,
    postPose1Id: number,
    postPose2Id: number,
    postPose3Id: number,
    instructionsText: string,
    benefitsText: string,
    assistanceText: string,
    payAttentionToText: string,
    variantsText: string,
    instructionsTextEnglish: string,
    benefitsTextEnglish: string,
    assistanceTextEnglish: string,
    payAttentionToTextEnglish: string,
    variantsTextEnglish: string
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      var updateResult = false;
      dispatch(loading(true));

      var request = { ...getState().positionForm.position };
      request!.mainPurpose = mainPurpose;
      request!.mainPurposeEnglish = mainPurposeEnglish;
      request!.types = types;
      request!.level = level;
      request!.sanskrit = sanskrit;
      request!.english = english;
      request!.swedish = swedish;
      request!.private = isPrivate;
      request!.prePose1Id = prePose1Id;
      request!.prePose2Id = prePose2Id;
      request!.prePose3Id = prePose3Id;
      request!.postPose1Id = postPose1Id;
      request!.postPose2Id = postPose2Id;
      request!.postPose3Id = postPose3Id;
      request!.instructionsText! = instructionsText!;
      request!.benefitsText! = benefitsText!;
      request!.assistanceText! = assistanceText!;
      request!.payAttentionToText! = payAttentionToText!;
      request!.variantsText! = variantsText!;
      request!.instructionsTextEnglish! = instructionsTextEnglish!;
      request!.benefitsTextEnglish! = benefitsTextEnglish!;
      request!.assistanceTextEnglish! = assistanceTextEnglish!;
      request!.payAttentionToTextEnglish! = payAttentionToTextEnglish!;
      request!.variantsTextEnglish! = variantsTextEnglish!;

      await PositionService.putApiPositionsUpdate(request!)
        .then(async (result) => {
          dispatch(setPosition(result));
          dispatch(updatePos(result));
          updateResult = true;
        })
        .catch((e: Error) => {
          dispatch(hasError((e as Error).message));
        });

      return updateResult;
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const positionFormStateSelector = (
  state: RootState
): PositionFormState => state.positionForm;

export default positionFormSlice.reducer;
